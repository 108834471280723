@import '../variables.scss';

.back-button-flex {
  top: 1em;
  align-self: flex-start;
}

.back-button {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 1em;
  align-self: flex-start;
  margin: 0.35rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-darkest;
  color: $primary-lighter;
  height: 2em;
  width: 12em;
  border: 0.5rem solid $primary-darker;
  border-radius: 6px;
  text-decoration: none;
  transition: color 0.25s;

  &:hover {
    color: white;
    cursor: pointer;
  }
}

.top-1 {
  top: 1em !important;
}

.top-2 {
  top: 2em !important;
}

.top-3 {
  top: 3em !important;
}

.top-4 {
  top: 4em !important;
}

.top-5 {
  top: 5em !important;
}

.left-1 {
  left: 1em !important;
}

.left-2 {
  left: 2em !important;
}

.left-3 {
  left: 3em !important;
}

.left-4 {
  left: 4em !important;
}

.left-5 {
  left: 5em !important;
}
