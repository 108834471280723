@import '../variables.scss';

#page-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: $primary;
  border: 0.5rem solid $primary-lighter;
  border-radius: 9px;
  color: $primary-lightest;
  padding: 1.5em;
  max-width: 1600px;
  width: 90%;

  // @media (max-width: '600px') {

  // }
  // @media (min-width: '601px') and (max-width: '1200px') {

  // }
  // @media (min-width: '1201px') {

  // }
}
