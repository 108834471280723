@import '../variables.scss';

.paragon-hexalignment-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .paragon-hexalignment {
    @extend .ml-2;
    @extend .mr-2;
  }
}
