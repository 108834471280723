@import '../variables.scss';

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  border: 0.5rem solid $primary-lighter;
  border-radius: 9px;
  color: $primary-lightest;
  text-align: center;
  max-width: 1600px;
  margin: 60px auto 5px;
  height: 4.5em;
  width: 90%;

  // @media (max-width: '600px') {

  // }
  // @media (min-width: '601px') and (max-width: '1200px') {

  // }
  // @media (min-width: '1201px') {

  // }
}

.nav-link-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-link {
  margin: 0.35rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-darkest;
  color: $primary-lighter;
  height: 1.5em;
  width: 7em;
  border: 0.25rem solid $primary-darker;
  border-radius: 6px;
  text-decoration: none;
  transition: transform 0.25s;

  &:hover {
    color: white;
    cursor: pointer;
    border-color: white;
  }
}

.chapter-selection-container {
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chapter-selection-input {
  text-align: center;
  margin-right: 1em;
  height: 2.25em;
  border: 0.25rem solid $primary-lighter;
  border-radius: 9px;
  background-color: $primary;
  color: $primary-lightest;
  font-size: 1em;
  font-weight: 900;
  outline: none;

  &:hover {
    color: white;
    border-color: $primary-darker;
    background-color: $primary-darkest;
  }

  @media (max-width: '600px') {
    max-width: 5em;
  }
  @media (min-width: '601px') and (max-width: '1200px') {
    max-width: 8em;
  }
  @media (min-width: '1201px') {
    max-width: 10em;
  }
}

.nav-icon {
  transition: color 0.5s;

  &:hover {
    color: white;
  }
}
