.known-festerfont-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;

  &:hover {
    color: white;
    cursor: pointer;
  }
}

.known-festerfont-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
  @extend .ml-1;
  @extend .mr-1;
}

.known-festerfont-icon {
  flex-grow: 1;
  margin: auto;
}

.known-festerfont-name {
  flex-grow: 8;
}
