.festerfont-class-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;

  &:hover {
    color: white;
    cursor: pointer;
  }
}

.festerfont-class-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
  @extend .ml-1;
  @extend .mr-1;

  .festerfont-class-icon {
    margin: auto;
  }

  .festerfont-class-name {
    flex-grow: 8;
    @extend .ml-1;
    @extend .mr-1;
  }
}
