@import '../variables.scss';

// .paragon-card-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 600px;
//   border: 0.25em solid $primary-lighter;
//   border-radius: 9px;

//   &:hover {
//     color: white;
//     cursor: pointer;
//   }

//   @extend .mb-2;
//   @extend .mr-1;
//   @extend .ml-1;
// }

.paragon-card-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  margin-top: 0.25em;

}

.paragon-card-icon {
  flex-grow: 0;
  margin: auto;
}

.paragon-card-hexalignment {
  flex-grow: 1;
  @extend .ml-1;
  @extend .mr-1;
}
