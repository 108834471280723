@import '../variables.scss';

.footer-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $primary;
  border: 0.5rem solid $primary-lighter;
  border-radius: 9px;
  color: $primary-lightest;
  max-width: 1600px;
  margin: 5px auto 60px;
  width: 90%;

  // @media (max-width: '600px') {

  // }
  // @media (min-width: '601px') and (max-width: '1200px') {

  // }
  // @media (min-width: '1201px') {

  // }
}

.footer-link-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-link {
  margin: 0.35rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-darkest;
  color: $primary-lighter;
  height: 2em;
  width: 12em;
  border: 0.5rem solid $primary-darker;
  border-radius: 6px;
  text-decoration: none;
  transition: transform 0.25s;

  &:hover {
    color: white;
    cursor: pointer;
  }
}

.footer-note-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-note {
  color: #ffffff21;
}

.top-page-arrow {
  margin-left: auto;
  border: 3px solid gray;
  border-radius: 50%;

  &:hover {
    color: white;
    cursor: pointer;
    transform: scale(1.15);
    border-color: black;
  }
}
