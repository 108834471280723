@import './components/components.scss';
@import './pages/pages.scss';
@import './variables.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  background: black;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    font-size: 8px;
  }
  @media (min-width: 601px) and (max-width: 1200px) {
    font-size: 10px;
  }
  @media (min-width: 1201px) {
    font-size: 12px;
  }
}

.page-title {
  font-size: 4em;
  font-weight: 900;
}

.header {
  font-size: 3em;
  font-weight: 900;
}

.header-left {
  font-size: 3em;
  font-weight: 900;
  text-align: left;
}

.subheader {
  font-size: 2em;
  font-weight: 700;
}

.subheader-left {
  font-size: 2em;
  font-weight: 700;
  text-align: left;
}

.description {
  font-size: 1.5em;
  font-weight: 300;
  font-style: italic;
  text-align: left;
}

.text {
  font-size: 1.5em;
  font-weight: 300;
  text-align: left;
}

.text-center {
  font-size: 1.5em;
  font-weight: 300;
  text-align: center;
}

.encrypted {
  font-family: 'Libre Barcode 39 Extended', cursive;
}

.icon {
  width: 28px;
  height: 28px;
}

.m-1 {
  margin: 0.5em;
}

.m-2 {
  margin: 1em;
}

.m-3 {
  margin: 1.5em;
}

.m-4 {
  margin: 2em;
}

.ml-1 {
  margin-left: 0.5em;
}

.ml-2 {
  margin-left: 1em;
}

.ml-3 {
  margin-left: 1.5em;
}

.ml-4 {
  margin-left: 2em;
}

.mr-1 {
  margin-right: 0.5em;
}

.mr-2 {
  margin-right: 1em;
}

.mr-3 {
  margin-right: 1.5em;
}

.mr-4 {
  margin-right: 2em;
}

.mt-1 {
  margin-top: 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.mt-3 {
  margin-top: 1.5em;
}

.mt-4 {
  margin-top: 2em;
}

.mb-1 {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.mb-3 {
  margin-bottom: 1.5em;
}

.mb-4 {
  margin-bottom: 2em;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.bold {
  font-weight: 700;
}

.boldest {
  font-weight: 900;
}

.page-container {
  width: 90%;
}

.z-front {
  z-index: 100;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.fg-1 {
  flex-grow: 1;
}

.fg-2 {
  flex-grow: 2;
}

.fg-3 {
  flex-grow: 3;
}

.fg-4 {
  flex-grow: 4;
}

.fg-5 {
  flex-grow: 5;
}

.fg-6 {
  flex-grow: 6;
}

.fg-7 {
  flex-grow: 7;
}

.fg-8 {
  flex-grow: 8;
}

.fg-9 {
  flex-grow: 9;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fs-0 {
  flex-shrink: 0;
}

.fb-1 {
  flex-basis: 1;
}

// .colorpulse-anim {
//   animation-duration: 6s;
//   animation-iteration-count: infinite;
//   animation-direction: alternate;
// }

// @keyframes colorpulse {
//   from {
//     color: white;
//   }

//   25% {
//     color: #1b001b;
//     transform: scale(1.1);
//   }

//   75% {
//     color: black;
//     transform: scale(1.1);
//   }

//   to {
//     color: grey;
//   }
// }
